import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import qs from 'qs';
import MainTemplate from 'components/templates/MainTemplate';
import { metaDescription, metaKeywords, title } from 'constants/meta';
import { Query } from 'react-apollo';
import itemForSearchByArticleQuery from 'graphql/queries/itemForSearchByArticle.graphql';
import itemsForSearchByNameQuery from 'graphql/queries/itemsForSearchByName.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import Redirect from 'components/atoms/Redirect';
import Pagination from 'components/molecules/Pagination';
import ItemsList from 'components/molecules/ItemsList';

const ITEMS_PER_PAGE = 30;

const StyledPagination = styled(Pagination)`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

const SearchPage = ({ location }) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const q = queryParams.q || '';

  if (queryParams.type === 'article') {
    const empty = (
      <MainTemplate>
        <Helmet>
          <title>{`Поиск по артикулу "${q}"`}</title>
          <meta name="keywords" content={metaKeywords.search} />
          <meta name="description" content={metaDescription.search} />
        </Helmet>
        <h1>{`Поиск по артикулу "${q}"`}</h1>
        <p>По вашему запросу ничего не найдено</p>
      </MainTemplate>
    );

    if (!q) {
      return empty;
    }

    return (
      <Query
        query={itemForSearchByArticleQuery}
        variables={{
          article: q.toLowerCase(),
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <LoadingPage />;
          }

          if (error) {
            return <ErrorLoadingPage />;
          }

          if (!data.item) {
            return empty;
          }

          return (
            <Redirect to={`/catalog/${data.item.category.translit}/${data.item.id}`} status={302} />
          );
        }}
      </Query>
    );
  }

  const rawPage = queryParams.page;

  if (typeof rawPage !== 'undefined' && (isNaN(+rawPage) || +rawPage <= 0)) {
    return <NotFoundPage />;
  }

  const page = +rawPage || 1;

  if (+rawPage === 1) {
    return (
      <Redirect
        to={{
          pathname: location.pathname,
          search: qs.stringify({ ...queryParams, page: undefined }, { arrayFormat: 'brackets' }),
        }}
      />
    );
  }

  if (!q) {
    return (
      <MainTemplate>
        <Helmet>
          <title>{`Поиск по запросу "${q}"`}</title>
          <meta name="keywords" content={metaKeywords.search} />
          <meta name="description" content={metaDescription.search} />
        </Helmet>
        <h1>{`Поиск по запросу "${q}"`}</h1>
        <p>По вашему запросу ничего не найдено</p>
      </MainTemplate>
    );
  }

  return (
    <Query
      query={itemsForSearchByNameQuery}
      variables={{
        q,
        qLower: q.toLowerCase(),
        qUpper: q.toUpperCase(),
        qFirstUpper: q
          ? (([s, ...tring]) => [s.toUpperCase(), tring.join('').toLowerCase()].join(''))(q)
          : '',
        offset: (page - 1) * ITEMS_PER_PAGE,
        itemsPerPage: ITEMS_PER_PAGE,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <LoadingPage />;
        }

        if (error) {
          return <ErrorLoadingPage />;
        }

        const totalPages = Math.ceil(
          data.itemsConnectionAggregated.aggregate.count / ITEMS_PER_PAGE,
        );

        if (page > totalPages && totalPages !== 0) {
          return <NotFoundPage />;
        }

        return (
          <MainTemplate>
            <Helmet>
              <title>{`Поиск по запросу "${q}"`}</title>
              <meta name="keywords" content={metaKeywords.search} />
              <meta name="description" content={metaDescription.search} />
            </Helmet>
            <h1>{`Поиск по запросу "${q}"`}</h1>
            {totalPages === 0 ? (
              <p>По вашему запросу ничего не найдено</p>
            ) : (
              <>
                <ItemsList
                  cols={[2, 3, 4]}
                  items={data.itemsConnection.edges.map((edge) => edge.node)}
                />
                <StyledPagination total={totalPages} currentPage={page} />
              </>
            )}
          </MainTemplate>
        );
      }}
    </Query>
  );
};

export default SearchPage;
